import { RouteObject } from 'react-router-dom';

import ProjectsPage from '.';
import { getProjectsList } from './api';
import SingleProjectPage from './SingleProjectPage';
import MrqPage from './ProjectMrqPage';
import ProjectRaciPage from './ProjectRaciPage';
import ProjectTaskPage from './ProjectTaskPage';

export const paths = {
  myProjects: "/projects",
  singleProject: "/projects/:projectId",
  projectTabs: "/projects/:projectId/:projectTab",
  mcsTabs: "/projects/:projectId/:projectTab/:mcsTab",
  mcsTab: "mcs",
  pcsTab: "pcs",
  mrq: "/projects/:projectId/mrq",
  raci: "/projects/:projectId/raci",
  task: "/projects/:projectId/task",
}

export const projectsRoutes: RouteObject[] = [
  {
    path: paths.myProjects,
    element: <ProjectsPage />,
    loader: getProjectsList,
  },
  {
    path: paths.singleProject,
    element: <SingleProjectPage />,
  },
  {
    path: paths.projectTabs,
    element: <SingleProjectPage />,
  },
  {
    path: paths.mcsTabs,
    element: <SingleProjectPage />,
  },
  {
    path: paths.mrq,
    element: <MrqPage />,
  },
  {
    path: paths.raci,
    element: <ProjectRaciPage />,
  },
  {
    path: paths.task,
    element: <ProjectTaskPage />,
  },
];
