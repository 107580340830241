import { MRT_Row, type MRT_ColumnDef } from 'material-react-table';
import {
  Autocomplete,
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
  Typography,
  menuClasses,
  selectClasses,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { useNavigate } from 'react-router-dom';
import icon from '../../../common/assets/icons/assym1.png';
import Project from './interfaces/projectInterface';
import { LinearProgressWithLabel } from '../../common/components/LinearProgressWithLabel';
import ProjectUser from './interfaces/projectUserInterface';
import { MouseEvent, SyntheticEvent, useEffect, useState } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ClearIcon from '@mui/icons-material/Clear';
import { deepPurple } from '@mui/material/colors';
import User from '../UsersPage/interface/userInterface';
import { TabUnselected } from '@mui/icons-material';

import sp_icon from '../../common/assets/images/sp_icon.png';
import autodesk_icon from '../../common/assets/images/autodesk.png';

import TaskOutlinedIcon from '@mui/icons-material/Task';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';

const userRoles: string[] = ['Lead', 'Member'];

interface MemberItemProps {
  member: ProjectUser;
  handleChange: (event: SelectChangeEvent) => void;
  handleDelete: (event: MouseEvent) => void;
}

// const Item = styled(Paper)(({ theme }) => ({
//     backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//     ...theme.typography.body2,
//     padding: theme.spacing(1),
//     textAlign: 'center',
//     color: theme.palette.text.secondary,
// }));

const MemberItem = ({
  member,
  handleChange,
  handleDelete,
}: MemberItemProps) => (
  // <Box pt={1}>
  <Stack
    direction="row"
    sx={{ alignItems: 'center', justifyContent: 'space-between' }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <AccountCircleIcon color="primary" fontSize="large" />
      <Typography pl={1}>{member.name}</Typography>
    </Box>

    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <FormControl variant="standard" sx={{ m: 0.5, minWidth: 140 }}>
        <Select
          labelId="select-label"
          id="select"
          name={member.userId}
          value={member.role}
          disableUnderline
          variant="standard"
          size="small"
          onChange={handleChange}
          sx={{
            [`& .${selectClasses.select}`]: {
              background: 'white',
              color: 'grey',
              borderRadius: '4px',
              padding: '10px 24px',
              backgroundColor: '#F3F4F6FF',
              border: 'none',
              '&:focus': {
                borderRadius: '12px',
                backgroundColor: '#F3F4F6FF',
                background: 'white',
                borderColor: deepPurple[50],
                border: 'none',
              },
            },
            [`& .${selectClasses.icon}`]: {
              right: '12px',
            },
          }}
        >
          {userRoles.map((role, i) => (
            <MenuItem key={i} value={role}>
              {role}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <ClearIcon color="primary" fontSize="small" />
    </Box>
  </Stack>
  // </Box>
);

const projectsColumns = (
  users: User[],
  openViewerModal: (row: MRT_Row<Project>) => void,
): MRT_ColumnDef<Project>[] => [
  {
    accessorKey: 'id',
    header: 'Id',
    enableEditing: false,
    size: 80,
    Edit: () => <div></div>,
  },
  {
    accessorKey: 'name',
    header: 'Project Name',
    size: 500,
    muiEditTextFieldProps: ({ cell, row, table }) => ({
      required: true,
      // variant:"outlined",
      size: 'small',
      sx: {
        p: '10px 0px 0px 0px',
      },
      InputLabelProps: {
        shrink: true,
        sx: {
          fontWeight: 700,
          fontSize: '18px',
          lineHeight: '26px',
          pb: 2,
          color: 'black',
        },
      },
    }),
  },
  {
    accessorKey: 'document',
    header: 'DCS',
    size: 40,
    enableEditing: false,
    Edit: () => null,
    enableColumnActions: false,
    Cell: ({ cell, row }) => {
      const name = row.original.name;

      // For cbl
      // const dcsLink = "https://enerfacprojectscom.sharepoint.com/sites/CB-Lincoln/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FCB%2DLincoln%2FShared%20Documents%2F" + name

      // For cb
      const dcsLink =
        'https://enerfacprojectscom.sharepoint.com/sites/CB-Montreal/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FCB%2DMontreal%2FShared%20Documents/Projects/' +
        name;
      return (
        <a href={dcsLink} target="_blank">
          <img src={sp_icon} height={'40px'} alt="SharePoint" />
        </a>
      );
    },
  },
  {
    accessorKey: 'raci',
    header: 'RACI',
    size: 40,
    enableEditing: false,
    enableColumnActions: false,
    Edit: () => null,
    Cell: ({ row, cell }) => {
      return (
        <a href={`/projects/${row.original.id}/raci`}>
          <AssignmentIndIcon color="primary" />
        </a>
      );
    },
  },
  {
    accessorKey: 'tasks',
    header: 'Tasks',
    size: 40,
    enableEditing: false,
    enableColumnActions: false,
    Edit: () => null,
    Cell: ({ row, cell }) => {
      return (
        <a href={`/projects/${row.original.id}/task`}>
          <TaskOutlinedIcon color="primary" />
        </a>
      );
    },
  },
  {
    accessorKey: 'model',
    header: '3D',
    size: 40,
    enableEditing: false,
    Edit: () => null,
    enableColumnActions: false,
    Cell: ({ cell, row }) => {
      const name = row.original.name;
      const dcsLink =
        'https://enerfacprojectscom.sharepoint.com/sites/CB-Montreal/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FCB%2DMontreal%2FShared%20Documents/' +
        name;
      return (
        <a
          // target="_blank"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openViewerModal(row);
          }}
        >
          <img src={autodesk_icon} height={'30px'} alt="SharePoint" />
        </a>
      );
    },
  },
  {
    accessorKey: 'mrq',
    header: 'MRQ',
    size: 40,
    enableEditing: false,
    enableColumnActions: false,
    Edit: () => null,
    Cell: ({ row, cell }) => {
      return (
        <a href={`/projects/${row.original.id}/mrq`}>
          <RequestQuoteIcon color="primary" />
        </a>
      );
    },
  },
  {
    accessorKey: 'users',
    header: 'Members',
    size: 150,
    Cell: ({ row, cell }) => {
      const members = cell.getValue() as ProjectUser[];
      const visibleMembers = members?.slice(0, 2); // Show only the first 2 members

      return (
        <>
          {visibleMembers?.map((member, index) => (
            <Chip key={index} label={member.name} />
          ))}
          {members?.length > 2 && (
            <Tooltip title={members.slice(2).join(', ')} arrow>
              <Chip label={`${members.length - 2}+`} />
            </Tooltip>
          )}
        </>
      );
    },
    Edit: ({ cell, column, row, table }) => {
      console.log({ cell });
      const [selectedUser, setSelectedUser] = useState<User | null>();
      const [refresh, setRefresh] = useState<true | false>();

      useEffect(() => {
        // const tableState = table.getState()
        // const isExitingCreateOrEditMode = tableState.creatingRow === null && tableState.editingRow === null;
        // if (isExitingCreateOrEditMode) {
        //     // Call your function to clear the data
        //     row.original["users"] = [];
        // }
      }, [selectedUser, refresh]);

      const handleNewMember = (
        event: SyntheticEvent<Element, Event>,
        value: User | null,
      ) => {
        setSelectedUser(value);
      };

      const handleAddMember = (event: MouseEvent) => {
        console.log({ selectedUser });
        if (
          selectedUser &&
          !row.original['users'].find((user) => user.userId === selectedUser.id)
        ) {
          row.original['users'].push({
            userId: selectedUser.id,
            name: selectedUser.name,
            role: 'Member',
          });
          row._valuesCache['users'] = row.original['users'];
        }
        setSelectedUser(null);
      };

      const handleRoleChange = (event: SelectChangeEvent) => {
        const { name, value } = event.target;
        console.log({ name, value });
        const user = row.original['users'].find((user) => user.userId === name);

        const updatedUsers = row.original['users'].map((user) => {
          if (user.userId === name) {
            // Update the role for the specific user
            return { ...user, role: value };
          }
          return user;
        });
        row.original.users = updatedUsers;
        row._valuesCache.users = updatedUsers;
        setRefresh((prev) => !prev);
      };
      const handleDelete = (event: MouseEvent) => {};

      const ProjectMembers = row.original['users'];

      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            sx={{ fontWeight: 700, fontSize: '13px', lineHeight: '26px' }}
          >
            Add Members
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '200px',
              mt: 1,
              overflow: 'auto',
            }}
          >
            <Box display={'flex'} mb={1}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={users}
                getOptionLabel={(user) => user.name} // Display the user's name
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} size="small" label="Member" />
                )}
                onChange={handleNewMember}
              />
              <IconButton aria-label="Example" onClick={handleAddMember}>
                <AddIcon />
              </IconButton>
            </Box>
            <Stack spacing={0}>
              {ProjectMembers?.map((member, i) => (
                <MemberItem
                  key={i}
                  member={member}
                  handleChange={handleRoleChange}
                  handleDelete={handleDelete}
                />
              ))}
            </Stack>
          </Box>
        </Box>
      );
    },
  },
];

export default projectsColumns;
