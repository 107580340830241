import { Box } from '@mui/material'
import React from 'react'
import HeadingComponent from '../../../common/components/HeadingComponent'
import RACITab from '../SingleProjectPage/tabs/RACI'
import { getProjectRACI } from '../SingleProjectPage/tabs/RACI/api'

const ProjectRaciPage = () => {
    return (
        <Box padding={"2rem"}>
            <HeadingComponent heading='RACI' />
            <RACITab getItems={getProjectRACI} />
        </Box>
    )
}

export default ProjectRaciPage