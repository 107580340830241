import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';

import Logo from "../../../assets/images/chinook_blue.png"
import CB_Logo from "../../../assets/images/cb.png"
import chinook_cb_logo from "../../../assets/images/chinook_cb.png"

import { AccountCircle } from '@mui/icons-material';
import { Button, Menu, MenuItem } from '@mui/material';
import { navItems } from './utils';
import { useMsal } from '@azure/msal-react';

const drawerWidth = 210;

const settings = ['Logout'];

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#F3F4F6FF",
    color: "Black",
    boxShadow: "none",
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        })
    }),
}));


const ResponsiveAppBar: React.FC<{
    open: boolean;
    handleDrawerClose: () => void;
    handleDrawerOpen: () => void;
}> = ({ open, handleDrawerOpen, handleDrawerClose }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { instance, accounts } = useMsal();
    const navigate = useNavigate()



    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // setAuth(event.target.checked);
    };

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logoutAndRedirect = () => {
        instance.logoutRedirect()
        navigate("/");
    };

    return (
        <AppBar position="relative" open={open}>
            <Toolbar variant='dense' sx={{ display: 'flex', justifyContent: "space-between" }}>
                <Box display="flex">
                    {/* <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton> */}
                    {/* <img src={Logo} alt="ChinookDT" style={{ height: "48px", width: "90px" }} /> */}
                    <img src={chinook_cb_logo} alt="ChinookDT" style={{ height: "35px", width: "250px" }} />

                </Box>

                <Box>

                </Box>

                <Box sx={{ display: "flex", gap: "15px" }}>
                    {accounts?.length ? navItems.map((item, index) => (
                        <Button
                            key={index}
                            href={item.link}
                            // variant="text"
                            // startIcon={item?.icon}
                            // color:"#3f429a" 
                            sx={{ textTransform: 'none', fontSize: "0.875rem", }}>
                            {item.label}
                        </Button>
                    ))
                        :
                        <></>
                    }
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                    >
                        <AccountCircle />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={logoutAndRedirect}>Logout</MenuItem>
                    </Menu>
                </Box>
            </Toolbar>
        </AppBar>
    );
}
export default ResponsiveAppBar;