import { Box, Button, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material'
import LaunchIcon from '@mui/icons-material/Launch';
import React from 'react'
import sp_icon from "../../../../../common/assets/images/sp_icon.png"
import Project from '../../../interfaces/projectInterface';
import PropTypes from 'prop-types'

interface DCSTabProps {
    projectData: Project | undefined,
}

const DCSTab = ({ projectData }: DCSTabProps) => {

    const handleClick = () => {
        // https://enerfacprojectscom.sharepoint.com/sites/DocumentControlSystem/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FDocumentControlSystem%2FShared%20Documents%2FMontreal
        // const dcsLink = "https://enerfacprojectscom.sharepoint.com/sites/DocumentControlSystem/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FDocumentControlSystem%2FShared%20Documents%2FMontreal/" + projectData?.name
        
        const dcsLink = "https://enerfacprojectscom.sharepoint.com/sites/CB-Lincoln/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FCB%2DLincoln%2FShared%20Documents%2F" + projectData?.name
        window.open(dcsLink, '_blank');
        // console.log(process.env.NODE_ENV)
    }

    return (
        <Box width="100%" sx={{
            justifyContent: "center", display: "flex"
        }}>
            <Card sx={{
                width: 370,
                "& .MuiCardMedia-root": { objectFit: "contain" },
                p: 2
            }}>
                <CardMedia
                    component="img"
                    sx={{ height: 140 }}
                    image={sp_icon}
                    title="sp icon"
                />
                <CardContent>
                    <Typography sx={{ fontSize: 18 }} gutterBottom>
                        Access Your Sharepoint Documents here.
                    </Typography>
                    <br />
                </CardContent>
                <CardActions disableSpacing>
                    <Button
                        sx={{ backgroundColor: "#1c9ca4", color: "#fff", ml: 2 }}
                        variant="contained"
                        endIcon={<LaunchIcon />}
                        onClick={handleClick}
                    >
                        Go to Sharepoint
                    </Button>
                </CardActions>
            </Card>
        </Box >
    )
}


export default DCSTab